import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { GLOBAL_THEME_DEFAULT } from '_constants';

// Entities
import Application from 'application/entities/Application';

// Contexts
import { useNavigationContext } from 'application/contexts/NavigationContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';

// Components
import { Logo } from 'ui/components/2-molecules/Logo/Logo';
import { Header } from 'ui/components/4-habitats/Header/Header';
import { Navigation } from 'ui/components/3-organisms/Navigation/Navigation';
import { ServiceNavigation } from 'ui/components/3-organisms/ServiceNavigation/ServiceNavigation';
import { LanguageSelector } from 'ui/components/3-organisms/LanguageSelector/LanguageSelector';
export interface HeaderFeatureProps {
	className?: string;
	lightHeader?: boolean;
	isTransitioning?: boolean;
}

export const HeaderFeature: React.FC<HeaderFeatureProps> = ({ lightHeader, isTransitioning }) => {
	const navContext = useNavigationContext();
	const pageContext = usePageContext();
	const SiteContext = useSiteContext();
	const globalTheme: string = SiteContext.settings.globalTheme?.setTheme ?? GLOBAL_THEME_DEFAULT;
	const router = useRouter();
	const [headerToggled, setHeaderToggled] = useState<boolean>(false);
	const closeMenuRef = useRef<HTMLLabelElement>(null);
	const lastServiceNavItemRef = useRef(null);

	useEffect(() => {
		if (headerToggled) {
			const handleKeyDown = (event) => {
				if (event.key === 'Tab' && document.activeElement === lastServiceNavItemRef.current) {
					event.preventDefault();
					closeMenuRef.current.focus();
				}
			};

			window.addEventListener('keydown', handleKeyDown);

			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		}
	}, [headerToggled]);

	useEffect(() => {
		const toggleHeader = () => {
			setHeaderToggled(false);
		};
		router.events.on('routeChangeStart', toggleHeader);
		return () => {
			router.events.off('routeChangeStart', toggleHeader);
		};
	}, [headerToggled, router.events]);

	const navigationItems = navContext?.getNavigation({
		currentUrl: pageContext.path,
	});
	const serviceNavigationItems = navContext?.getNavigation({
		byName: 'service',
	});

	// TODO: Implement and get data from Umbraco
	const languages = [
		{ name: 'Dansk', locale: 'da', path: '/da' },
		{ name: 'Engelsk', locale: 'en-US', path: '/en-US' },
		{ name: 'Tysk', locale: 'de', path: '/de' },
	];

	const currentLanguage = languages.find((language) => language.locale === Application?.locale?.baseName);

	const dictionary = useDictionaryContext();

	const menuLabel = dictionary.getValue('Header.Menu');
	const closeMenuLabel = dictionary.getValue('Header.CloseMenu');
	const skipToContentLabel = dictionary.getValue('Header.SkipToContent');
	const skipToContentTitle = dictionary.getValue('Header.SkipToContentTitle');

	return (
		<Header
			toggled={headerToggled}
			toggleMenu={() => setHeaderToggled(!headerToggled)}
			logoArea={<Logo headingSlug={globalTheme} />}
			languageSelector={currentLanguage && <LanguageSelector languages={languages} currentLanguage={currentLanguage} />}
			lightHeader={lightHeader}
			menuLabel={menuLabel}
			closeMenuLabel={closeMenuLabel}
			skipToContentLabel={skipToContentLabel}
			skipToContentTitle={skipToContentTitle}
			closeMenuRef={closeMenuRef}
			isTransitioning={isTransitioning}
		>
			<Navigation navigationItems={navigationItems} style="primary" />
			<ServiceNavigation serviceNavigationItems={serviceNavigationItems} lastItemRef={lastServiceNavItemRef} />
		</Header>
	);
};

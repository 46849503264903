import { ArticleAuthorProps } from 'ui/components/2-molecules/Article/ArticleAuthor/ArticleAuthor';
import { ArticleProps } from 'ui/components/4-habitats/Article/Article';
import { FooterProps } from 'ui/components/4-habitats/Footer/Footer';
import { LinkProps } from 'ui/components/1-atoms/Action/Link/Link';
import { PreviewCardProps } from 'ui/components/2-molecules/Card/PreviewCard/PreviewCard';
import { HeroCardProps } from 'ui/components/2-molecules/Card/HeroCard/HeroCard';
import { DictionaryInterface } from 'application/repositories/dictionaryRepository';

export const footerMapper = (footer: Content.Footer, globalTheme: string, dictionary: Partial<DictionaryInterface>): FooterProps => {
	return (
		footer && {
			heading: footer.heading,
			ctaLink: {
				name: footer.ctaLink?.name,
				url: footer.ctaLink?.url,
			},
			office: footer.footerCards?.map((footerCard) => {
				const { heading, text, text2 } = footerCard.content.properties;
				return {
					heading: heading,
					address: text,
					zipcode: text2,
				};
			}),
			cvrNumber: footer.cvrNumber,
			some: footer.some,
			policies: {
				name: footer.policies?.name,
				url: footer.policies?.url,
			},
			contact: {
				telephone: footer.telephone,
				email: footer.email,
			},
			globalTheme: globalTheme,
			dictionary: {
				ContactTitle: dictionary.getValue('Footer.contactTitle'),
				FollowUs: dictionary.getValue('Footer.followUs'),
				CVR: dictionary.getValue('Footer.CVR'),
				Legal: dictionary.getValue('Footer.Legal'),
			},
		}
	);
};

export const heroMapper = (props: Content.HeroCard): HeroCardProps => {
	if (!props) return null;
	const { content, settings } = props;
	return {
		...content.properties,
		image: {
			url: content.properties.image?.url,
			altText: content.properties.image?.properties?.altText,
			focalPoint: content.properties.image?.focalPoint,
		},
		bgColor: content.properties.color,
		kicker: {
			firstWord: content.properties.kickerFirstWord,
			secondWord: content.properties.kickerSecondWord,
		},
		noOverlay: settings.properties.noOverlay,
		kickerLeft: settings.properties.kickerLeft,
	};
};

export const newsWithTagsMapper = (newsPage: Content.NewsPageWithTags): ArticleProps => {
	const { pageHeader, newsDate, newsDateStatus, textList, newsAuthors, newsTeaser, newsHeading, showNewsAuthorsBio } = newsPage.properties;

	return {
		heroCard: pageHeader.length ? heroMapper(pageHeader.find((ph) => ph.documentType === 'heroCard')) : null,
		articleAuthors: personMapper(newsAuthors),
		showArticleAuthorsBio: showNewsAuthorsBio,
		articleDate: dateValidOrNull(newsDate),
		articleDateStatus: newsDateStatus,
		articleTeaser: newsTeaser,
		articleHeading: newsHeading,
		richTextSections: textList?.map((item) => ({
			content: item.content.properties?.content,
			readMoreText: item.content.properties?.readMoreText,
			readMoreButtonText: item.content.properties?.readMoreButtonText,
		})),
	} as ArticleProps;
};

export const newsMapper = (newsPage: Content.NewsPage): ArticleProps => {
	const { pageHeader, newsDate, newsDateStatus, textList, newsAuthors, newsTeaser, newsHeading, showNewsAuthorsBio } = newsPage.properties;
	return {
		heroCard: pageHeader.length ? heroMapper(pageHeader.find((ph) => ph.documentType === 'heroCard')) : null,
		articleAuthors: personMapper(newsAuthors),
		showArticleAuthorsBio: showNewsAuthorsBio,
		articleDate: dateValidOrNull(newsDate),
		articleDateStatus: newsDateStatus,
		articleTeaser: newsTeaser,
		articleHeading: newsHeading,
		richTextSections: textList?.map((item) => ({
			content: item.content.properties?.content,
			readMoreText: item.content.properties?.readMoreText,
			readMoreButtonText: item.content.properties?.readMoreButtonText,
		})),
	} as ArticleProps;
};

export const articleMapper = (articlePage: Content.ArticlePage): ArticleProps => {
	const {
		layoutType,
		articleBgColor,
		kicker,
		mainHeading,
		articleInfo,
		link,
		teaserHeading,
		teaser,
		heading,
		image,
		articleTextList,
		showFormButtonText,
		formHtmlCode,
		authors,
		articleAuthorHeading,
		showArticleAuthorsBio,
	} = articlePage.properties ?? {};

	return {
		layoutType,
		articleBgColor,
		heroCard: {
			heading: mainHeading,
			kicker: {
				firstWord: '',
				secondWord: kicker,
			},
			image: {
				url: image?.url,
				altText: image?.properties?.altText,
				focalPoint: image?.focalPoint,
			},
			imageCropping: 'cover',
			kickerLeft: true,
			noOverlay: true,
		},
		articleAuthors: personMapper(authors),
		articleAuthorHeading,
		showArticleAuthorsBio,
		articleInfo: {
			infoColumns: articleInfo?.map(articleInfoColumnMapper),
			linkButton: link,
		},
		articleTeaserHeading: teaserHeading,
		articleTeaser: teaser,
		articleHeading: heading,
		richTextSections: articleTextList?.map((item) => {
			if (item.documentType === 'articleText') {
				return {
					content: item.content.properties?.content,
					readMoreText: item.content.properties?.readMoreText,
					readMoreButtonText: item.content.properties?.readMoreButtonText,
				};
			} else if (item.documentType === 'articleMedia') {
				return {
					image: {
						url: item.content.properties?.image?.url,
						altText: item.content.properties?.image?.properties?.altText,
						focalPoint: item.content.properties?.image?.focalPoint,
					},
					caption: item.content.properties?.caption,
					documentType: item.documentType,
				};
			}
		}),
		articleShowFormButtonText: showFormButtonText,
		formHtmlCode,
	} as ArticleProps;
};

export const personMapper = (persons: Content.Person[]): ArticleAuthorProps[] =>
	persons?.map(({ properties }) => ({
		fullName: properties?.fullName,
		workTitle: properties?.workTitle,
		email: properties?.email,
		bio: properties?.bio,
		image: {
			url: properties?.image?.url,
			altText: properties?.image?.properties?.altText,
			focalPoint: properties?.image?.focalPoint,
		},
		linkedIn: {
			title: properties?.linkedIn?.name,
			href: properties?.linkedIn?.url,
			target: properties?.linkedIn?.target,
		},
	}));

export const articleInfoColumnMapper = (articleInfoColumn: Content.ArticleInfoColumn): Articles.ArticleInfo => ({
	heading: articleInfoColumn.content.properties?.heading,
	line: articleInfoColumn.content.properties?.line,
	line2: articleInfoColumn.content.properties?.line2,
});

export const linkMapper = (link: Content.Link): LinkProps => ({
	children: link?.name,
	href: link?.url,
});

export const tagMapper = (tag: Content.Tag): Tags.Tag => ({
	tagName: tag.name,
	id: tag.id,
	guid: tag.key,
});

export const dateValidOrNull = (dateString: string): string => {
	const validDate = new Date(dateString).getFullYear() > 1970;
	return validDate ? dateString : null;
};

export const newsCardMapper = (page: Content.NewsPage): News.NewsArticle => {
	const hero = page.properties.pageHeader?.find((ph) => ph.documentType === 'heroCard')?.content.properties;

	return {
		documentType: 'newsPage',
		heading: hero?.heading,
		url: page.url,
		kicker: hero.kickerFirstWord ?? hero.kickerSecondWord,
		text: page.properties.newsTeaser,
		date: dateValidOrNull(page.properties.newsDate),
		image: {
			url: hero?.image?.url,
			focalPoint: hero?.image?.focalPoint,
			properties: {
				altText: hero?.image?.properties?.altText,
			},
		},
		featuredImage: {
			url: hero?.featuredImage?.url,
			focalPoint: hero?.featuredImage?.focalPoint,
			properties: {
				altText: hero?.featuredImage?.properties?.altText,
			},
		},
	};
};

export const newsWithTagsCardMapper = (page: Content.NewsPage): News.NewsArticleWithTags => {
	const hero = page.properties.pageHeader?.find((ph) => ph.documentType === 'heroCard')?.content.properties;
	const authorName = page.properties?.newsAuthors?.length > 0 && page.properties?.newsAuthors[0].properties?.fullName;
	const sortOrder = page.sortOrder;

	return {
		documentType: 'newsPageWithTags',
		heading: hero?.heading,
		url: page.url,
		kicker: hero?.kickerFirstWord ?? hero?.kickerSecondWord,
		text: page.properties?.newsTeaser,
		date: dateValidOrNull(page.properties?.newsDate),
		image: {
			url: hero?.image?.url,
			focalPoint: hero?.image?.focalPoint,
			properties: {
				altText: hero?.image?.properties?.altText,
			},
		},
		featuredImage: {
			url: hero?.featuredImage?.url,
			focalPoint: hero?.featuredImage?.focalPoint,
			properties: {
				altText: hero?.featuredImage?.properties?.altText,
			},
		},
		tags: page.properties?.tags?.map((tag) => tag.name),
		author: authorName ?? null,
		sortOrder: sortOrder,
	};
};

export const articlePreviewMapper = (article: Articles.Article): PreviewCardProps => {
	return {
		headline: article.mainHeading,
		link: {
			url: article.url,
		},
		text: article.teaser,
		image: {
			url: article.image?.url,
			altText: article.image?.properties?.altText,
			focalPoint: article.image?.focalPoint,
		},
		featuredImage: {
			url: article.featuredImage?.url,
			altText: article.featuredImage?.properties?.altText,
			focalPoint: article.featuredImage?.focalPoint,
		},
		tags: {
			first: article.location,
			second: `${article.articleInfo[0].line} ${article.articleInfo[0].line2}`,
		},
	};
};

export const newsWithTagsPreviewMapper = (news: News.NewsArticleWithTags, activeTag: Tags.Tag | null, allTranslation: string): PreviewCardProps => {
	let tag = '';

	if (activeTag) {
		if (activeTag.tagName.toLowerCase() === allTranslation.toLowerCase()) {
			tag = news.tags && news.tags.length > 0 ? news.tags[0] : '';
		} else {
			tag = activeTag.tagName;
		}
	} else {
		tag = news.tags && news.tags.length > 0 ? news.tags[0] : '';
	}

	return {
		headline: news.heading,
		link: {
			url: news.url,
		},
		text: news.text,
		tags: {
			first: news.author || news.kicker,
			second: tag,
		},
		image: {
			url: news.image?.url,
			altText: news.image?.properties?.altText,
			focalPoint: news.image?.focalPoint,
		},
		featuredImage: {
			url: news.featuredImage?.url,
			altText: news.featuredImage?.properties?.altText,
			focalPoint: news.featuredImage?.focalPoint,
		},
		imageSize: 'large',
		imageCropping: 'cover',
	};
};

export const newsPreviewMapper = (news: News.NewsArticle): PreviewCardProps => ({
	headline: news.heading,
	link: {
		url: news.url,
	},
	text: news.text,
	tags: {
		first: news.kicker,
		second: news.date,
	},
	image: {
		url: news.image?.url,
		focalPoint: news.image?.focalPoint,
		altText: news.image.properties?.altText || 'Preview image',
	},
	featuredImage: {
		url: news.featuredImage?.url,
		focalPoint: news.featuredImage?.focalPoint,
		altText: news.featuredImage?.properties?.altText || 'Preview image',
	},
	imageSize: 'large',
	imageCropping: 'cover',
});

import SwiperCore, { Scrollbar, A11y } from 'swiper';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CaseSliderItem, CaseSliderItemProps } from './CaseSliderItem/CaseSliderItem';
import styles from './CaseSlider.module.scss';
import classNames from 'classnames';

SwiperCore.use([Scrollbar, A11y]);

export interface CaseSliderProps {
	className?: string;
	slides?: CaseSliderItemProps[];
}

export const CaseSlider: React.FC<CaseSliderProps> = ({ slides, className }) => {
	const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
	const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

	const handlePrevClick = () => {
		if (swiperInstance) {
			swiperInstance.slidePrev();
		}
	};

	const handleNextClick = () => {
		if (swiperInstance) {
			swiperInstance.slideNext();
		}
	};

	const handleSlideChange = () => {
		if (swiperInstance) {
			setActiveSlideIndex(swiperInstance.realIndex);
		}
	};

	return (
		<div className={styles.CaseSlider}>
			<Swiper
				loop
				spaceBetween={50}
				slidesPerView={1}
				className={classNames(styles.CaseSlider, styles[`u-color___${slides[activeSlideIndex].bgColor}`])}
				breakpoints={{
					// when window width is >= 320px
					320: {
						slidesPerView: 1,
						spaceBetween: 0,
					},
				}}
				onSwiper={setSwiperInstance}
				onSlideChange={handleSlideChange}
				autoHeight
			>
				{slides.map((slide, index, arr) => (
					<SwiperSlide key={index}>
						<CaseSliderItem
							{...slide}
							slideNumber={arr.length}
							onHandlePrevClick={slides.length > 1 ? handlePrevClick : undefined}
							onHandleNextClick={slides.length > 1 ? handleNextClick : undefined}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

import { ColorOptions } from 'helpers/types';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import styles from './CaseSliderItem.module.scss';
import classNames from 'classnames';
import { Picture, PictureProps } from 'ui/components/1-atoms/Media/Picture/Picture';
import { Container } from 'ui/components/4-habitats/Container/Container';
import { Button } from 'ui/components/1-atoms/Action/Button/Button';
import { Icon } from 'ui/components/1-atoms/Media/Icon/Icon';
import LeftButtonArrowKp from 'ui/icons/icon-left-button-arrow-kp.svg';
import RightButtonArrowKp from 'ui/icons/icon-right-button-arrow-kp.svg';
import { TopLevelGrid } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGrid';
import { TopLevelGridCell } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGridCell';
import { Kicker, KickerProps } from 'ui/components/1-atoms/Kicker/Kicker';
import { Link, LinkProps } from 'ui/components/1-atoms/Action/Link/Link';
import { LinkButton } from 'ui/components/1-atoms/Action/LinkButton/LinkButton';
import { useEffect, useState } from 'react';

export interface CaseSliderItemProps {
	heading: string;
	bgColor: ColorOptions;
	kicker: KickerProps;
	seeAllLink?: LinkProps;
	seeAllLinkLabel?: string;
	caseLink: LinkProps;
	image?: PictureProps;
	slideNumber?: number;
	onHandlePrevClick?: () => void;
	onHandleNextClick?: () => void;
	navigationPrevLabel?: string;
	navigationNextLabel?: string;
	globalTheme?: string;
}

export const CaseSliderItem: React.FC<CaseSliderItemProps> = ({
	heading,
	bgColor,
	kicker,
	seeAllLink,
	seeAllLinkLabel,
	caseLink,
	image,
	slideNumber,
	onHandlePrevClick,
	onHandleNextClick,
	navigationPrevLabel,
	navigationNextLabel,
	globalTheme,
}) => {
	const [mobileLayout, setMobileLayout] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setMobileLayout(window.innerWidth <= 768);
		};

		handleResize();

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className={classNames(styles.CaseSliderItem, styles[`u-color___${bgColor}`])}>
			<Container width="Standard">
				<div className={styles.CaseSliderItem_wrapper}>
					{kicker.firstWord && kicker.secondWord && <Kicker className={styles.CaseSlider_kicker} {...kicker}></Kicker>}
					{seeAllLink.title && (
						<Link
							className={classNames(styles.CaseSlider_link, {
								[styles.CaseSlider_link___noKicker]: !kicker.firstWord && !kicker.secondWord,
							})}
							{...seeAllLink}
							iconLink={true}
							title={seeAllLinkLabel}
							globalTheme={globalTheme}
						>
							{seeAllLink.title}
						</Link>
					)}

					{slideNumber > 1 && (
						<div className={styles.CaseSliderItem_navigation}>
							{globalTheme === 'kogp' ? (
								<>
									<Button
										className={styles.CaseSliderItem_navigationPrev}
										onClick={onHandlePrevClick}
										style="noAnimation"
										aria-label={navigationPrevLabel}
									>
										<Icon size="md">
											<LeftButtonArrowKp />
										</Icon>
									</Button>
									<Button
										className={styles.CaseSliderItem_navigationNext}
										onClick={onHandleNextClick}
										style="noAnimation"
										aria-label={navigationNextLabel}
									>
										<Icon size="md">
											<RightButtonArrowKp />
										</Icon>
									</Button>
								</>
							) : (
								<>
									<Button aria-label={navigationPrevLabel} onClick={onHandlePrevClick} style="arrowPrev" children={''}></Button>
									<Button aria-label={navigationNextLabel} onClick={onHandleNextClick} style="arrowNext" children={''}></Button>
								</>
							)}
						</div>
					)}
					<div className={styles.CaseSliderItem_content}>
						<TopLevelGrid nogutter>
							<TopLevelGridCell desktopWidth="18col" desktopOffset="0col" mobileWidth="100">
								<div className={styles.CaseSliderItem_heading}>
									{heading && (
										<Heading className={styles.CaseSliderItem_text} style="xl" headingLevel="h2">
											{heading}
										</Heading>
									)}
									{caseLink.title && !mobileLayout && (
										<LinkButton
											style="primary"
											inheritsColor={true}
											className={styles.CaseSliderItem_button}
											target={caseLink.target}
											href={caseLink.href}
										>
											{caseLink.title}
										</LinkButton>
									)}
								</div>
								<div className={styles.CaseSliderItem_imageWrapper}>
									<div className={styles.CaseSliderItem_image}>
										<Picture
											url={image.url}
											altText={image.altText}
											focalPoint={image.focalPoint}
											imageCropping="cover"
											sizes="(max-width: 768px) 100vw, (max-width: 1728px) 66vw, 1150px"
										/>
									</div>

									{caseLink.title && mobileLayout && (
										<LinkButton
											style="primary"
											inheritsColor={true}
											className={styles.CaseSliderItem_button}
											target={caseLink.target}
											href={caseLink.href}
										>
											{caseLink.title}
										</LinkButton>
									)}
								</div>
							</TopLevelGridCell>
						</TopLevelGrid>
					</div>
				</div>
			</Container>
		</div>
	);
};

import classNames from 'classnames';
import PentiaLogo from 'ui/icons/pentia_logo_bookmark.svg';
import KpLogo from 'ui/icons/klausenpartners_logo_tagline.svg';
import styles from './Logo.module.scss';
import { Icon } from 'ui/components/1-atoms/Media/Icon/Icon';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';
import { GLOBAL_THEMES, GLOBAL_THEME_DEFAULT } from '_constants';
import { Paragraph } from 'ui/components/1-atoms/Paragraph/Paragraph';

export interface LogoProps {
	className?: string;
	headingSlug?: string;
}

export const Logo: React.FC<LogoProps> = ({ className, headingSlug = GLOBAL_THEME_DEFAULT }) => {
	return (
		<Link className={classNames(styles.Logo, className)} href="/">
			<Paragraph className={styles.Logo_heading}>
				{GLOBAL_THEMES.find((theme) => theme.slug === headingSlug).name}
			</Paragraph>
			<Icon className={styles.Logo_asset} size="auto">
				{headingSlug === 'kogp' && <KpLogo />}
				{(headingSlug === 'pentia-dk' || headingSlug === 'pentia-se') && <PentiaLogo />}
			</Icon>
		</Link>
	);
};

import ColorOptions from 'helpers/types';
import styles from './LogoList.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { Picture } from 'ui/components/1-atoms/Media/Picture/Picture';
import { Container } from 'ui/components/4-habitats/Container/Container';
import { Grid } from 'ui/components/4-habitats/Grid/Grid';
import { GridCell } from 'ui/components/4-habitats/Grid/GridCell';
import { TopLevelGrid } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGrid';
import { TopLevelGridCell } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGridCell';
import classNames from 'classnames';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';

export interface LogoListProps {
	bgColor?: ColorOptions;
	trumpetHeading?: string;
	heading: string;
	logos: {
		url: string;
		altText: string;
	}[];
	logoAndLinkList?: {
		link: {
			href: string;
			text: string;
			target: string;
		};
		logo: {
			url: string;
			altText: string;
		};
	}[];
}

export const LogoList: React.FC<LogoListProps> = ({ bgColor = 'white', trumpetHeading, heading, logos, logoAndLinkList }) => {
	return (
		<div className={classNames(styles.LogoList, styles[`u-color___${bgColor}`])}>
			<Container width="Standard">
				{trumpetHeading && <p className={styles.LogoList_trumpetHeading}>{trumpetHeading}</p>}

				{heading && (
					<Heading className={styles.LogoList_heading} headingLevel="h2" style="xl">
						{heading}
					</Heading>
				)}
				{logoAndLinkList?.length > 0 ? (
					<TopLevelGrid container wrap>
						<TopLevelGridCell desktopWidth="15col" desktopOffset="2col" offsetDirection="right" mobileWidth="100">
							<Grid wrap largeGutter>
								{logoAndLinkList.map((item, index) => (
									<GridCell className={styles.LogoList_logo} mobileWidth="50" desktopWidth="25" key={index}>
										<Link {...item.link} >
											<Picture {...item.logo} sizes="(max-width: 768px) 50vw, 240px" />
										</Link>
									</GridCell>
								))}
							</Grid>
						</TopLevelGridCell>
					</TopLevelGrid>
				) : (
					logos?.length > 0 && (
						<TopLevelGrid container wrap>
							<TopLevelGridCell desktopWidth="15col" desktopOffset="2col" offsetDirection="right" mobileWidth="100">
								<Grid wrap largeGutter>
									{logos.map((logo, index) => (
										<GridCell className={styles.LogoList_logo} mobileWidth="50" desktopWidth="25" key={index}>
											<Picture {...logo} sizes="(max-width: 768px) 50vw, 240px" />
										</GridCell>
									))}
								</Grid>
							</TopLevelGridCell>
						</TopLevelGrid>
					)
				)}
				{}
			</Container>
		</div>
	);
};
